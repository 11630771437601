body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans', sans-serif;
}

body #root {
  min-height: 100%;
}
.calender > div{
  line-height: 0;
}